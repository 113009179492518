import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

import { ELoadingStatus } from '@http/enums';
import { RootState } from '../store';
import { IUserItem } from '@models/user-item';
import { v1 } from '@api/v1';
import { IRequestFollowProps } from '@http/models/request-follow-props';

interface IUserListState {
  error?: string;
  items: IUserItem[];
  loadingStatus: ELoadingStatus;
  page: number;
  scrollPosition: number;
  total: number;
}

const initialState: IUserListState = {
  items: [],
  loadingStatus: ELoadingStatus.Idle,
  page: 1,
  scrollPosition: 0,
  total: 0,
};

const slice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    addItems: (state, action: PayloadAction<IUserItem[]>) => {
      state.items = uniqBy([...state.items, ...action.payload], 'id');
    },
    setItems: (state, action: PayloadAction<IUserItem[]>) => {
      state.items = action.payload;
    },
    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
    setLoadingStatus: (state, action: PayloadAction<ELoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state) => {
        state.loadingStatus = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { items, total, page, loadmore } = action.payload;
        state.items = loadmore ? uniqBy([...state.items, ...items], 'id') : items;
        state.total = total;
        state.page = page;
        state.loadingStatus = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        state.loadingStatus = ELoadingStatus.Failed;
        state.error = action.payload as string;
      });
  },
});

const loadData = createAsyncThunk(
  'userList/loadData',
  async ({ loadmore, search, userId }: IRequestFollowProps, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = loadmore ? state.userList.page + 1 : 1;

    try {
      const response = await v1.user.get({
        page,
        userId,
        ...(search !== '' && { search }),
      });

      if (response.errorMsg) {
        return rejectWithValue(response.errorMsg);
      }

      return { items: response.items, total: response.total, page, loadmore };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      return rejectWithValue(errorMessage);
    }
  },
);

const userList = {
  ...slice.actions,
  loadData,
  selectItems: (state: RootState) => state.userList.items,
  selectError: (state: RootState) => state.followers.error,
  selectTotal: (state: RootState) => state.userList.total,
  selectLoadingStatus: (state: RootState) => state.userList.loadingStatus,
  selectScrollPosition: (state: RootState) => state.userList.scrollPosition,
};

export const userListReducer = slice.reducer;
export default userList;
