import { ApiResponse } from '../../../types';
import { apiClients } from '../../';
import { IFormFile } from '../../../form-file';

interface CreateAvatarRequest {
  file: IFormFile;
}

interface CreateAvatarResponse extends ApiResponse {
  id?: string;
  url?: string;
}

export const userAvatar = {
  post: (request: CreateAvatarRequest) => {
    const formData = new FormData();
    formData.append('file', request.file.blob, request.file.name);
    return apiClients.default.post<CreateAvatarResponse>('v1/user/avatar/file', formData);
  },
};
