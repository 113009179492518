import React from 'react';
import { Link, LinkProps } from 'react-router';

import cn from 'classnames/bind';
import s from './button.module.scss';
const cx = cn.bind(s);

interface BaseButtonProps {
  'children'?: React.ReactNode;
  'className'?: string;
  'variant':
    | 'primary'
    | 'secondary'
    | 'light'
    | 'dark'
    | 'blue'
    | 'ghost'
    | 'brand-primary'
    | 'link'
    | 'disabled'
    | 'inverse';
  'size'?: 'xs' | 'default';
  'style'?: React.CSSProperties;
  'tabIndex'?: number;
  'name'?: string;
  'fill'?: boolean;
  'data-testing'?: string;
}

interface ButtonAsButtonProps extends BaseButtonProps {
  as?: 'button'; // По умолчанию
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  buttonRef?: React.Ref<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

interface ButtonAsDivProps extends BaseButtonProps {
  as: 'div';
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  buttonRef?: React.Ref<HTMLDivElement>;
}

interface ButtonAsLinkProps extends BaseButtonProps, Omit<LinkProps, 'to'> {
  as: 'link';
  href: string;
  buttonRef?: React.Ref<HTMLAnchorElement>;
}

type ButtonProps = ButtonAsButtonProps | ButtonAsDivProps | ButtonAsLinkProps;

const Button: React.FC<ButtonProps> = (props) => {
  const {
    as = 'button',
    children,
    className,
    variant,
    size,
    style,
    tabIndex,
    name,
    fill,
    'data-testing': dataTesting,
    ...rest
  } = props;

  const computedClassName = cx('content', className, variant, {
    [String(size)]: Boolean(size),
    [s.fill]: Boolean(fill),
  });

  if (as === 'link') {
    const { href, onClick, buttonRef, ...linkProps } = rest as ButtonAsLinkProps;
    return (
      <Link
        className={computedClassName}
        style={style}
        to={href}
        onClick={onClick}
        ref={buttonRef}
        tabIndex={tabIndex}
        name={name}
        data-testing={dataTesting}
        {...linkProps}
      >
        {children}
      </Link>
    );
  }

  if (as === 'div') {
    const { onClick, buttonRef, ...divProps } = rest as ButtonAsDivProps;
    return (
      <div
        className={computedClassName}
        onClick={onClick}
        ref={buttonRef}
        tabIndex={tabIndex}
        name={name}
        role="button"
        style={style}
        data-testing={dataTesting}
        {...divProps}
      >
        {children}
      </div>
    );
  }

  const {
    onClick,
    buttonRef,
    type = 'button',
    disabled,
    ...buttonProps
  } = rest as ButtonAsButtonProps;
  return (
    <button
      className={computedClassName}
      onClick={onClick}
      ref={buttonRef}
      type={type}
      disabled={disabled}
      tabIndex={tabIndex}
      name={name}
      style={style}
      data-testing={dataTesting}
      {...buttonProps}
    >
      {children}
    </button>
  );
};

export default Button;
