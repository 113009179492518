import { isEmpty } from 'lodash';
import { AxiosRequestConfig } from 'axios';

import { ApiResponse, Fetching, ListApiResponse, CreateResponse } from '@http/types';
import { apiClients } from '@api/index';
import { EProjectModerationStatus, EProjectSortingOrder } from '@http/enums';
import { IUserItem } from '@models/user-item';
import { IProjectItem } from '@models/project-item';
import { ICommentItem } from '@models/comment-item';
import { ICreateProjectRequest } from '@models/create-project-request';
import { IProjectList } from '@http/models/project-list';
import { Paging } from '../../../../types';
import { parseToEnum } from '@utils/type-parser';

export interface IAuthorInRequest {
  id?: (string | undefined)[];
  name?: string;
  isFollowed?: boolean;
}

interface CommentRequest {
  text?: string;
  parentId?: number;
}

export interface ProjectResponse extends IProjectItem, ApiResponse {}

export interface ProjectListRequest extends Paging {
  id?: (string | undefined)[];
  challengeId?: (number | undefined)[];
  eventId?: (string | number | undefined)[];
  userLiked?: boolean;
  author?: IAuthorInRequest;
  skills?: Fetching;
  medias?: Fetching;
  sortingOrder?: EProjectSortingOrder;
  hideSecret?: boolean;
}

export interface CommentsListResponse extends ListApiResponse<ICommentItem>, Paging {}
export interface CommentsRequest extends Paging {
  projectId: string;
}

export const project = {
  get: async (request: ProjectListRequest) => {
    const response = await apiClients.default.get<IProjectList>(`v1/project`, {
      params: request,
    });

    if (response && !isEmpty(response.items)) {
      (response.items as IProjectItem[]).forEach((item: IProjectItem) => {
        item.status = parseToEnum(EProjectModerationStatus, item.status);
      });
    }

    return response;
  },
  post: async (request: ICreateProjectRequest, config?: AxiosRequestConfig) => {
    const formData = new FormData();

    Object.entries(request).forEach((item) => {
      if (item[0] === 'images') {
        for (let i = 0; i < item[1].length; i++) {
          formData.append(item[0], item[1][i]);
        }
      } else {
        formData.append(item[0], item[1]);
      }
    });

    return await apiClients.default.post<CreateResponse>('v1/project', formData, config);
  },
  PROJECT_ID: {
    get: async (projectId: number) => {
      const response = await apiClients.default.get<ProjectResponse>(`v1/project/${projectId}`, {
        params: {
          recentlyLikedUser: {
            take: 3,
          },
        },
      });

      response.status = parseToEnum(EProjectModerationStatus, response.status);

      return response;
    },
    likes: {
      get: async ({
        projectId,
        page,
        take = 25,
      }: {
        projectId: string;
        page: number;
        take?: number;
      }) => {
        return await apiClients.default.get<ListApiResponse<IUserItem>>(
          `v1/project/${projectId}/like`,
          {
            params: {
              page: page,
              take: take,
            },
          },
        );
      },
    },
    comment: {
      get: async (request: CommentsRequest) => {
        return await apiClients.default.get<CommentsListResponse>(
          `v1/project/${request.projectId}/comment`,
          {
            params: {
              take: request.take ?? 20,
              page: request.page,
            },
          },
        );
      },
      post: async (projectId: string, request: CommentRequest) => {
        return await apiClients.default.post<CreateResponse>(
          `v1/project/${projectId}/comment`,
          request,
        );
      },
    },
  },
  complaint: {
    reason: {
      get: async () => {
        return await apiClients.default.get<IProjectList>(`v1/project/complaint/reason`);
      },
    },
  },
};
