import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Capacitor } from '@capacitor/core';

import { AppProviders } from './providers/AppProviders';
import { SessionCheck } from './SessionCheck';
import EmptyPage404 from './views/pages/empty-page-404/empty-page-404';

const MobileLayout = React.lazy(async () => import('./views/layout/mobile-layout'));

const ErrorBoundary = () => (
  <div>
    <h1>Что-то пошло не так</h1>
    <p>Мы не смогли загрузить страницу. Попробуйте позже.</p>
  </div>
);

const routes = createBrowserRouter([
  {
    path: '/',
    element: (
      <SessionCheck>
        <AppProviders>
          <MobileLayout />
        </AppProviders>
      </SessionCheck>
    ),
    errorElement: <ErrorBoundary />,
    children: [{ path: '*', element: <EmptyPage404 /> }],
  },
]);

const App = () => {
  const carrotquestApiKey = process.env.REACT_APP_CARROTQUEST_API_KEY;
  const isNative = Capacitor.isNativePlatform();
  const isInIframe = window.self !== window.top;

  return (
    <HelmetProvider>
      {!isNative && !isInIframe && carrotquestApiKey && (
        <Helmet>
          <script type="text/javascript">
            {`
            !function(){function t(t,e){return function(){window.carrotquestasync.push(t,arguments)}}if("undefined"==typeof carrotquest){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://cdn.carrotquest.app/api.min.js",document.getElementsByTagName("head")[0].appendChild(e),window.carrotquest={},window.carrotquestasync=[],carrotquest.settings={};for(var n=["connect","track","identify","auth","onReady","addCallback","removeCallback","trackMessageInteraction"],a=0;a<n.length;a++)carrotquest[n[a]]=t(n[a])}}(),carrotquest.connect("${carrotquestApiKey}");
          `}
          </script>
        </Helmet>
      )}
      <RouterProvider router={routes} />
    </HelmetProvider>
  );
};

export default App;
