import { ReactComponent as flag_Russia } from './flag/russia.svg';
//
import { ReactComponent as emoji_Smile } from './emoji/smile.svg';
//
import { ReactComponent as icon_Android } from './icon/android.svg';
import { ReactComponent as icon_AnotherMenu } from './icon/another-menu.svg';
import { ReactComponent as icon_ArrowDownDropdown } from './icon/arrow-down-dropdown.svg';
import { ReactComponent as icon_ArrowDown } from './icon/arrow-down.svg';
import { ReactComponent as icon_ArrowLeft } from './icon/arrow-left.svg';
import { ReactComponent as icon_ArrowRight } from './icon/arrow-right.svg';
import { ReactComponent as icon_ArrowLeftSmall } from './icon/arrow-left-small.svg';
import { ReactComponent as icon_ArrowRightSmall } from './icon/arrow-right-small.svg';
import { ReactComponent as icon_Backward } from './icon/backward.svg';
import { ReactComponent as icon_Badge } from './icon/badge.svg';
import { ReactComponent as icon_Bank } from './icon/bank.svg';
import { ReactComponent as icon_BarGraph } from './icon/bar-graph.svg';
import { ReactComponent as icon_Calendar } from './icon/calendar.svg';
import { ReactComponent as icon_Camera } from './icon/camera.svg';
import { ReactComponent as icon_Cancel } from './icon/cancel.svg';
import { ReactComponent as icon_CancelCircle } from './icon/cancel-circle.svg';
import { ReactComponent as icon_Chat } from './icon/chat.svg';
import { ReactComponent as icon_Check } from './icon/check.svg';
import { ReactComponent as icon_Checkbox } from './icon/checkbox.svg';
import { ReactComponent as icon_CheckboxChecked } from './icon/checkbox-checked.svg';
import { ReactComponent as icon_CheckCircle } from './icon/check-circle.svg';
import { ReactComponent as icon_CheckCircleBorder } from './icon/check-circle-border.svg';
import { ReactComponent as icon_CheckGear } from './icon/check-gear.svg';
import { ReactComponent as icon_CheckSquare } from './icon/check-square.svg';
import { ReactComponent as icon_ChevronLeft } from './icon/chevron-left.svg';
import { ReactComponent as icon_ChevronRight } from './icon/chevron-right.svg';
import { ReactComponent as icon_Clock } from './icon/clock.svg';
import { ReactComponent as icon_Close } from './icon/close.svg';
import { ReactComponent as icon_CoinsRound } from './icon/coins-round.svg';
import { ReactComponent as icon_CoinsHmao } from './icon/coins-hmao.svg';
import { ReactComponent as icon_Coins } from './icon/coins.svg';
import { ReactComponent as icon_CoinsNornickel } from './icon/coins-nornickel.svg';
import { ReactComponent as icon_CoinsHighland } from './icon/coins-highland.svg';
import { ReactComponent as icon_Comet } from './icon/comet.svg';
import { ReactComponent as icon_Collapse } from './icon/collapse.svg';
import { ReactComponent as icon_Expand } from './icon/expand.svg';
import { ReactComponent as icon_Comments } from './icon/comments.svg';
import { ReactComponent as icon_CommentsOutline } from './icon/comments-outline.svg';
import { ReactComponent as icon_Copy } from './icon/copy.svg';
import { ReactComponent as icon_Deal } from './icon/deal.svg';
import { ReactComponent as icon_Delete } from './icon/delete.svg';
import { ReactComponent as icon_Devil } from './icon/devil.svg';
import { ReactComponent as icon_Eljur } from './icon/eljur.svg';
import { ReactComponent as icon_Email } from './icon/email.svg';
import { ReactComponent as icon_Emoji } from './icon/emoji.svg';
import { ReactComponent as icon_ExclamationMark } from './icon/exclamation-mark.svg';
import { ReactComponent as icon_ExclamationMarkCircle } from './icon/exclamation-mark-circle.svg';
import { ReactComponent as icon_Eye } from './icon/eye.svg';
import { ReactComponent as icon_EyeClosed } from './icon/eye-closed.svg';
import { ReactComponent as icon_Favorites } from './icon/favorites.svg';
import { ReactComponent as icon_FavoritesFilled } from './icon/favorites-filled.svg';
import { ReactComponent as icon_Feed } from './icon/feed.svg';
import { ReactComponent as icon_FeedOutline } from './icon/feed-outline.svg';
import { ReactComponent as icon_Fire } from './icon/fire.svg';
import { ReactComponent as icon_FireRound } from './icon/fire-round.svg';
import { ReactComponent as icon_Firework } from './icon/firework.svg';
import { ReactComponent as icon_Forward } from './icon/forward.svg';
import { ReactComponent as icon_FotographyFocus } from './icon/fotography-focus.svg';
import { ReactComponent as icon_Ghost } from './icon/ghost.svg';
import { ReactComponent as icon_GooglePainted } from './icon/google-painted.svg';
import { ReactComponent as icon_Grid } from './icon/grid.svg';
import { ReactComponent as icon_Hands } from './icon/hands.svg';
import { ReactComponent as icon_Heart } from './icon/heart.svg';
import { ReactComponent as icon_HeartOutline } from './icon/heart-outline.svg';
import { ReactComponent as icon_Help } from './icon/help.svg';
import { ReactComponent as icon_HmaoAchievementsLogo } from './icon/hmao-achievements-logo.svg';
import { ReactComponent as icon_Human } from './icon/human.svg';
import { ReactComponent as icon_Hyperlink } from './icon/hyperlink.svg';
import { ReactComponent as icon_InfoSupport } from './icon/info-support.svg';
import { ReactComponent as icon_Kebab } from './icon/kebab.svg';
import { ReactComponent as icon_Key } from './icon/key.svg';
import { ReactComponent as icon_Landscape } from './icon/landscape.svg';
import { ReactComponent as icon_LayerCircle } from './icon/layer-circle.svg';
import { ReactComponent as icon_Level } from './icon/level.svg';
import { ReactComponent as icon_Location } from './icon/location.svg';
import { ReactComponent as icon_Lock } from './icon/lock.svg';
import { ReactComponent as icon_LockBold } from './icon/lock-bold.svg';
import { ReactComponent as icon_Loupe } from './icon/loupe.svg';
import { ReactComponent as icon_LoupeBig } from './icon/loupe-big.svg';
import { ReactComponent as icon_LoupeBigColor } from './icon/loupe-big-color.svg';
import { ReactComponent as icon_Media } from './icon/media.svg';
import { ReactComponent as icon_menu } from './icon/menu.svg';
import { ReactComponent as icon_NavigationNext } from './icon/navigation-next.svg';
import { ReactComponent as icon_NavigationPrev } from './icon/navigation-prev.svg';
import { ReactComponent as icon_NoInternet } from './icon/no-internet-icon.svg';
import { ReactComponent as icon_Notification } from './icon/notification.svg';
import { ReactComponent as icon_Odnoklassniki } from './icon/odnoklassniki.svg';
import { ReactComponent as icon_Pause } from './icon/pause.svg';
import { ReactComponent as icon_Pen } from './icon/pen.svg';
import { ReactComponent as icon_Pencil } from './icon/pencil.svg';
import { ReactComponent as icon_Play } from './icon/play.svg';
import { ReactComponent as icon_PlayCircle } from './icon/play-circle.svg';
import { ReactComponent as icon_PlayerIconsFill } from './icon/player-icons-fill.svg';
import { ReactComponent as icon_Plus } from './icon/plus.svg';
import { ReactComponent as icon_PlusRound } from './icon/plus-round.svg';
import { ReactComponent as icon_Preview } from './icon/preview.svg';
import { ReactComponent as icon_Rocket } from './icon/rocket.svg';
import { ReactComponent as icon_Shape } from './icon/shape.svg';
import { ReactComponent as icon_Share } from './icon/share.svg';
import { ReactComponent as icon_Skull } from './icon/skull.svg';
import { ReactComponent as icon_Smartphone } from './icon/smartphone.svg';
import { ReactComponent as icon_Snail } from './icon/snail.svg';
import { ReactComponent as icon_Star } from './icon/star.svg';
import { ReactComponent as icon_StarGray } from './icon/star_gray.svg';
import { ReactComponent as icon_StarProgress } from './icon/star_progress.svg';
import { ReactComponent as icon_Store } from './icon/store.svg';
import { ReactComponent as icon_Timer } from './icon/timer.svg';
import { ReactComponent as icon_TriangleExclamationMark } from './icon/triangle-exclamation-mark.svg';
import { ReactComponent as icon_Unlock } from './icon/unlock.svg';
import { ReactComponent as icon_User } from './icon/user.svg';
import { ReactComponent as icon_UserApprove } from './icon/user-approve.svg';
import { ReactComponent as icon_UserBlocklist } from './icon/user-blocklist.svg';
import { ReactComponent as icon_UserCircle } from './icon/user-circle.svg';
import { ReactComponent as icon_UserFilled } from './icon/user-filled.svg';
import { ReactComponent as icon_UserProjectEdit } from './icon/user-project-edit.svg';
import { ReactComponent as icon_UserRemove } from './icon/user-remove.svg';
import { ReactComponent as icon_Vk } from './icon/vk.svg';
import { ReactComponent as icon_VkFill } from './icon/vk-fill.svg';
import { ReactComponent as icon_VolumeOff } from './icon/volume-off.svg';
import { ReactComponent as icon_VolumeUp } from './icon/volume-up.svg';
import { ReactComponent as icon_WarnCircle } from './icon/warn-circle.svg';
import { ReactComponent as icon_Watch } from './icon/watch.svg';
import { ReactComponent as icon_Pass } from './icon/pass.svg';
import { ReactComponent as icon_Fail } from './icon/fail.svg';
import { ReactComponent as icon_WhiteCross } from './icon/white-cross.svg';
import { ReactComponent as icon_NotificationQuiz } from './icon/notification-quiz.svg';
import { ReactComponent as icon_Download } from './icon/download.svg';
//
import { ReactComponent as img_Avatar } from './img/avatar.svg';
import { ReactComponent as img_NewMessage } from './img/new-message.svg';
import { ReactComponent as img_Password } from './icon/password.svg';
import { ReactComponent as img_RoundLogo } from './img/round-logo.svg';
import { ReactComponent as img_SadFace } from './img/sad-face.svg';
import { ReactComponent as img_Time } from './img/time.svg';
import { ReactComponent as img_TRex } from './img/t-rex.svg';
import { ReactComponent as img_AchievementStub } from './img/achievement-stub.svg';
import { ReactComponent as img_AchievementStubNornickel } from './img/achievement-stub-nornickel.svg';
import { ReactComponent as eventPrizeBannerStub } from './img/event-prize-banner-stub.svg';
import { ReactComponent as img_Tooltip1Line } from './img/tooltip_1line.svg';
import { ReactComponent as img_Tooltip2Line } from './img/tooltip_2line.svg';
import { ReactComponent as img_Tooltip3Line } from './img/tooltip_3line.svg';
//
import { ReactComponent as logo_Round } from './logo/round.svg';
import { ReactComponent as logo_RoundInline2 } from './logo/round-inline-2.svg';
import { ReactComponent as logo_Round_Developed } from './logo/logo-round.svg';

const Flag = {
  Russia: flag_Russia,
};

const Icon = {
  Android: icon_Android,
  AnotherMenu: icon_AnotherMenu,
  ArrowDownDropdown: icon_ArrowDownDropdown,
  ArrowDown: icon_ArrowDown,
  ArrowLeft: icon_ArrowLeft,
  ArrowRight: icon_ArrowRight,
  ArrowLeftSmall: icon_ArrowLeftSmall,
  ArrowRightSmall: icon_ArrowRightSmall,
  Backward: icon_Backward,
  Badge: icon_Badge,
  Bank: icon_Bank,
  BarGraph: icon_BarGraph,
  Calendar: icon_Calendar,
  Camera: icon_Camera,
  Cancel: icon_Cancel,
  CancelCircle: icon_CancelCircle,
  Chat: icon_Chat,
  Check: icon_Check,
  Checkbox: icon_Checkbox,
  CheckboxChecked: icon_CheckboxChecked,
  CheckCircle: icon_CheckCircle,
  CheckCircleBorder: icon_CheckCircleBorder,
  CheckGear: icon_CheckGear,
  CheckSquare: icon_CheckSquare,
  ChevronLeft: icon_ChevronLeft,
  ChevronRight: icon_ChevronRight,
  Clock: icon_Clock,
  Close: icon_Close,
  CoinsRound: icon_CoinsRound,
  CoinsHmao: icon_CoinsHmao,
  Coins: icon_Coins,
  CoinsNornickel: icon_CoinsNornickel,
  CoinsHighland: icon_CoinsHighland,
  Comet: icon_Comet,
  Collapse: icon_Collapse,
  Expand: icon_Expand,
  Comments: icon_Comments,
  CommentsOutline: icon_CommentsOutline,
  Copy: icon_Copy,
  Deal: icon_Deal,
  Pass: icon_Pass,
  Fail: icon_Fail,
  Delete: icon_Delete,
  Devil: icon_Devil,
  Download: icon_Download,
  Eljur: icon_Eljur,
  Email: icon_Email,
  Emoji: icon_Emoji,
  ExclamationMark: icon_ExclamationMark,
  ExclamationMarkCircle: icon_ExclamationMarkCircle,
  Eye: icon_Eye,
  EyeClosed: icon_EyeClosed,
  Favorites: icon_Favorites,
  FavoritesFilled: icon_FavoritesFilled,
  Feed: icon_Feed,
  FeedOutline: icon_FeedOutline,
  Fire: icon_Fire,
  FireRound: icon_FireRound,
  Firework: icon_Firework,
  Forward: icon_Forward,
  FotographyFocus: icon_FotographyFocus,
  Ghost: icon_Ghost,
  GooglePainted: icon_GooglePainted,
  GrayStar: icon_StarGray,
  Grid: icon_Grid,
  Hands: icon_Hands,
  Heart: icon_Heart,
  HeartOutline: icon_HeartOutline,
  Help: icon_Help,
  HmaoAchievementsLogo: icon_HmaoAchievementsLogo,
  Human: icon_Human,
  Hyperlink: icon_Hyperlink,
  InfoSupport: icon_InfoSupport,
  Kebab: icon_Kebab,
  Key: icon_Key,
  Landscape: icon_Landscape,
  LayerCircle: icon_LayerCircle,
  Level: icon_Level,
  Location: icon_Location,
  Lock: icon_Lock,
  LockBold: icon_LockBold,
  Loupe: icon_Loupe,
  LoupeBig: icon_LoupeBig,
  LoupeBigColor: icon_LoupeBigColor,
  Media: icon_Media,
  Menu: icon_menu,
  NavigationNext: icon_NavigationNext,
  NavigationPrev: icon_NavigationPrev,
  NoInternet: icon_NoInternet,
  Notification: icon_Notification,
  Odnoklassniki: icon_Odnoklassniki,
  Pause: icon_Pause,
  Pen: icon_Pen,
  Pencil: icon_Pencil,
  Play: icon_Play,
  PlayCircle: icon_PlayCircle,
  PlayerIconsFill: icon_PlayerIconsFill,
  Plus: icon_Plus,
  PlusRound: icon_PlusRound,
  Preview: icon_Preview,
  Rocket: icon_Rocket,
  Shape: icon_Shape,
  Share: icon_Share,
  Skull: icon_Skull,
  Smartphone: icon_Smartphone,
  Snail: icon_Snail,
  Star: icon_Star,
  StarProgress: icon_StarProgress,
  Store: icon_Store,
  Timer: icon_Timer,
  TriangleExclamationMark: icon_TriangleExclamationMark,
  Unlock: icon_Unlock,
  User: icon_User,
  UserApprove: icon_UserApprove,
  UserBlocklist: icon_UserBlocklist,
  UserCircle: icon_UserCircle,
  UserFilled: icon_UserFilled,
  UserProjectEdit: icon_UserProjectEdit,
  UserRemove: icon_UserRemove,
  Vk: icon_Vk,
  VkFill: icon_VkFill,
  VolumeOff: icon_VolumeOff,
  VolumeUp: icon_VolumeUp,
  WarnCircle: icon_WarnCircle,
  Watch: icon_Watch,
  WhiteCross: icon_WhiteCross,
  NotificationQuiz: icon_NotificationQuiz,
};

const Img = {
  AchievementStub: img_AchievementStub,
  AchievementStubNornickel: img_AchievementStubNornickel,
  Avatar: img_Avatar,
  EventPrizeBannerStub: eventPrizeBannerStub,
  NewMessage: img_NewMessage,
  Password: img_Password,
  RoundLogo: img_RoundLogo,
  SadFace: img_SadFace,
  Time: img_Time,
  TRex: img_TRex,
  Tooltip1Line: img_Tooltip1Line,
  Tooltip2Line: img_Tooltip2Line,
  Tooltip3Line: img_Tooltip3Line,
};

const Logo = {
  Round: logo_Round,
  RoundDeveloped: logo_Round_Developed,
  RoundInline2: logo_RoundInline2,
};

export const LogoCompanies = {
  Round: logo_Round,
};

const Emoji = {
  Smile: emoji_Smile,
};

export const Svg = {
  Emoji,
  Flag,
  Icon,
  Img,
  Logo,
  LogoCompanies,
};
