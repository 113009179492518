import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

import { RootState } from '../store';
import { INotificationItem } from '@http/models/notification-item';
import { v1 } from '@api/v1';
import { ELoadingStatus } from '../../http/enums';

interface INotificationListState {
  error?: string;
  items: INotificationItem[];
  loading: ELoadingStatus;
  page: number;
  scrollPosition: number;
  total: number;
}

const initialState: INotificationListState = {
  items: [],
  loading: ELoadingStatus.Idle,
  page: 1,
  scrollPosition: 0,
  total: 0,
};

const slice = createSlice({
  name: 'notificationList',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<ELoadingStatus>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<INotificationItem[]>) => {
      state.items = action.payload;
    },
    addItems: (state, action: PayloadAction<INotificationItem[]>) => {
      state.items = uniqBy([...state.items, ...action.payload], 'date');
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadData.pending, (state) => {
        state.loading = ELoadingStatus.Loading;
        state.error = undefined;
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { items, total, page, loadMore } = action.payload;

        state.items = loadMore ? uniqBy([...state.items, ...items], 'date') : items;
        state.total = total;
        state.page = page;
        state.loading = ELoadingStatus.Succeeded;
      })
      .addCase(loadData.rejected, (state, action) => {
        state.loading = ELoadingStatus.Failed;
        state.error = action.payload as string;
      });
  },
});

export const loadData = createAsyncThunk(
  'notificationList/loadData',
  async (loadMore: boolean | undefined, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const page = loadMore ? state.notificationList.page + 1 : 1;

    try {
      const response = await v1.notification.get({
        page,
        take: 25,
      });

      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }

      return {
        items: response.items,
        total: response.total,
        page,
        loadMore,
      };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      return rejectWithValue(errorMessage);
    }
  },
);

const notificationList = {
  ...slice.actions,
  loadData,
  selectLoading: (state: RootState) => state.notificationList.loading,
  selectItems: (state: RootState) => state.notificationList.items,
  selectError: (state: RootState) => state.notificationList.error,
  selectTotal: (state: RootState) => state.notificationList.total,
  selectScrollPosition: (state: RootState) => state.notificationList.scrollPosition,
};

export const notificationListReducer = slice.reducer;
export default notificationList;
