import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { Svg } from '@svg/index';
import Button from '@components/button/button';

import s from './EmptyContent.module.scss';

interface IEmptyContent {
  className?: string;
  title?: string;
  text?: string;
  btnText?: string;
  image?: JSX.Element;
  showClose?: boolean;
  onButtonClick?: () => void;
  onClose?: () => void;
}

export function EmptyContent(props: IEmptyContent) {
  const { className = '', title, text, btnText, image, showClose, onClose, onButtonClick } = props;
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    if (window.history.state && window.history.state.idx > 0) {
      return navigate(-1);
    }
    return navigate('/');
  }, []);

  const handleClose = useCallback(() => {
    if (onClose) {
      return onClose();
    }
    return handleBack();
  }, [onClose, handleBack]);

  const handleButtonClick = useCallback(() => {
    if (onButtonClick) {
      return onButtonClick();
    }
    return handleBack();
  }, [onButtonClick, handleBack]);

  return (
    <div className={`${s.container} ${className}`}>
      {showClose && (
        <div className={s.close} onClick={handleClose}>
          <Svg.Icon.Close />
        </div>
      )}
      <div className={s.center}>
        {Boolean(image) ? (
          <div className={s.image}>{image}</div>
        ) : (
          <div className={s.image}>
            <Svg.Img.TRex />
          </div>
        )}
        {title && <div className={s.title}>{title}</div>}
        {text && <div className={s.text}>{text}</div>}
      </div>
      {btnText && (
        <Button onClick={handleButtonClick} variant="light" className={s.btn}>
          {btnText}
        </Button>
      )}
    </div>
  );
}
