import React, { ReactNode, useCallback, useState } from 'react';

import { Dialog, IDialogProps } from '@components/dialog/dialog';

interface IDialogContext {
  showDialog: (dialogProps: IDialogProps) => void;
  hideDialog: (dialogId?: string) => void;
  updateDialog: (dialogId: string, updatedProps: Partial<IDialogProps>) => void;
}

export const DialogContext = React.createContext<IDialogContext | undefined>(undefined);

interface IDialogProviderProps {
  children: ReactNode;
}

interface IDialogWithId extends IDialogProps {
  id: string;
}

export const DialogProvider: React.FC<IDialogProviderProps> = ({ children }) => {
  const [dialogs, setDialogs] = useState<IDialogWithId[]>([]);

  const showDialog = useCallback((dialogProps: IDialogProps) => {
    const newDialog: IDialogWithId = {
      id: `dialog-${Date.now()}`,
      ...dialogProps,
    };
    setDialogs((prevDialogs) => [...prevDialogs, newDialog]);

    return newDialog.id;
  }, []);

  const hideDialog = useCallback(
    (dialogId?: string) =>
      setDialogs((prevDialogs) =>
        dialogId
          ? prevDialogs.filter((dialog) => dialog.id !== dialogId)
          : prevDialogs.slice(0, -1),
      ),
    [],
  );

  const updateDialog = useCallback(
    (dialogId: string, updatedProps: Partial<IDialogProps>) =>
      setDialogs((prevDialogs) =>
        prevDialogs.map((dialog) =>
          dialog.id === dialogId ? { ...dialog, ...updatedProps } : dialog,
        ),
      ),
    [],
  );

  return (
    <DialogContext.Provider value={{ showDialog, hideDialog, updateDialog }}>
      <>
        {children}
        {dialogs.map((dialog) => {
          const defaultProps: Partial<IDialogProps> = {
            withOverlay: true,
          };

          return <Dialog key={dialog.id} {...defaultProps} {...dialog} />;
        })}
      </>
    </DialogContext.Provider>
  );
};
