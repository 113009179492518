import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../button/button';
import { Svg } from '@svg/index';

import styles from './dialog.module.scss';

export interface IDialogProps {
  onClickCancel?: () => void;
  onClickOk?: () => void;
  onClickClose?: () => void;
  style?: any;
  title?: string;
  text?: string;
  cancelBtnText?: string;
  okBtnText?: string;
  children?: React.ReactNode;
  className?: string;
  withOverlay?: boolean;
}

export const Dialog = (props: IDialogProps) => {
  const { t } = useTranslation();
  const overlayRef = React.useRef<any>(null);

  const handleClickOutside = (e: any) => {
    props.onClickClose?.();
    props.onClickCancel?.();
  };

  const handleClickInside = (e: any) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const overflowElement: any = document.getElementsByClassName('infinite-scroll-component')[0];
    const overflowBefore = overflowElement?.style.overflow;

    document.body.classList.add('prevent-scroll');

    if (overflowElement) {
      overflowElement.style.overflow = 'hidden';
    }

    return () => {
      document.body.classList.remove('prevent-scroll');
      if (overflowElement) {
        overflowElement.style.overflow = overflowBefore;
      }
    };
  }, []);

  return (
    <div
      className={styles.container}
      data-overlay={props.withOverlay}
      onClick={handleClickOutside}
      ref={overlayRef}
    >
      <div
        className={props.className ? `${props.className} ${styles.dialog}` : styles.dialog}
        style={props.style}
        onClick={handleClickInside}
      >
        {props.onClickClose && (
          <button className={styles.close} onClick={props.onClickClose}>
            <Svg.Icon.Cancel />
          </button>
        )}
        {props.children ? (
          props.children
        ) : (
          <>
            {props.title && <div className={styles.title}>{props.title}</div>}
            {props.text && <div className={styles.text}>{props.text}</div>}
            {(props.onClickCancel || props.onClickOk) && (
              <div className={styles.btns}>
                {props.onClickCancel && (
                  <Button variant="link" className={styles.btn} onClick={props.onClickCancel}>
                    {props.cancelBtnText || t('common_dialog__btn_cancel')}
                  </Button>
                )}
                {props.onClickOk && (
                  <Button variant="link" className={styles.btn} onClick={props.onClickOk}>
                    {props.okBtnText || t('common_dialog__btn_good')}
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
