import React, { useMemo, useRef, useState } from 'react';
import Spacing from '@components/spacing/spacing';
import cn from 'classnames/bind';

import s from './popup.module.scss';
const cx = cn.bind(s);

export interface IPopupProps {
  before?: JSX.Element | string | number;
  btnText?: string;
  children?: React.ReactNode;
  hidden?: boolean;
  positionHorizontal?: 'left' | 'center' | 'right';
  positionVertical?: 'top' | 'top48' | 'bottom' | 'center';
  showTime?: number; // sec
  text?: JSX.Element | string | number;
  title?: string;
  variant?: 'dark' | 'light';
  width?: 'auto' | 'fill';
  align?: 'left' | 'center' | 'right';
  className?: string;
  onClick?: () => void;
  callback?: () => void;
  ['data-testing']?: string;
}

const PopupComponent = (props: IPopupProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const timerRef = useRef<any>();

  useMemo(() => {
    if (props.title || props.text || props.children) {
      setIsVisible(true);
    }

    if (props.showTime && props.showTime > 0) {
      timerRef.current = setTimeout(() => {
        setIsVisible(false);
        props.callback && props.callback();
      }, props.showTime * 1000);
    }

    return () => clearTimeout(timerRef.current);
  }, [props, timerRef]);

  if (!isVisible) return null;

  return (
    <div
      className={cx(s.container, {
        [props.className as string]: !!props.className,
      })}
      data-theme={props.variant ?? 'dark'}
      data-position-vertical={props.positionVertical ?? 'bottom'}
      data-position-horizontal={props.positionHorizontal ?? 'center'}
      data-width={props.width ?? 'auto'}
      data-align={props.align ?? 'left'}
      hidden={!isVisible}
      data-testing={props['data-testing']}
    >
      {props.before && <div className={s.before}>{props.before}</div>}
      <div className={s.content}>
        {props.title && <div className={s.title}>{props.title}</div>}
        {props.title && props.text && <Spacing size="h8" />}
        {props.text && <div className={s.text}>{props.text}</div>}
        {(props.text || props.title) && props.btnText && <Spacing size="h8" />}
        {props.btnText && (
          <div onClick={props.onClick} className={s.btn}>
            {props.btnText}
          </div>
        )}
        {props.children && <div className={s.children}>{props.children}</div>}
      </div>
    </div>
  );
};

export default React.memo(PopupComponent);
