import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ELoadingStatus } from '../http/enums';
import storage from '../storage';
import { PopupPortal } from '@components/popup-portal/popup-portal';
import Popup from '@components/popup/popup';

interface IProfileLoaderProps {
  children: React.ReactNode;
}

export const ProfileLoaderProvider: React.FC<IProfileLoaderProps> = ({ children }) => {
  const dispatch = useDispatch();

  const loading = useSelector(storage.profile.selectLoading);
  const error = useSelector(storage.profile.selectError);
  const isAuthorized = useSelector(storage.identity.selectAuthorized);

  useEffect(() => {
    if (isAuthorized) {
      dispatch(storage.profile.loadData());
    }
  }, [isAuthorized]);

  return (
    <>
      {loading === ELoadingStatus.Failed && error && (
        <PopupPortal>
          <Popup text={error} showTime={3} />
        </PopupPortal>
      )}
      {children}
    </>
  );
};
