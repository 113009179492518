import React, { createContext, ReactNode, useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate, useBlocker } from 'react-router';
import { useDialog } from '../hooks/useDialog';

interface INavigationBlockerContext {
  setBlockerConfig: (config: Partial<IBlockerConfig>) => void;
  startBlockingNavigation: () => void;
  stopBlockingNavigation: () => void;
  isBlockedNavigation: boolean;
  hideDialog: () => void;
}

export interface IBlockerConfig {
  title: string;
  text: string;
  confirmText: string;
  cancelText: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const NavigationBlockerContext = createContext<INavigationBlockerContext | undefined>(
  undefined,
);

export const NavigationBlockerProvider = ({ children }: { children: ReactNode }) => {
  const [blockerConfig, setBlockerConfig] = useState<IBlockerConfig>({
    title: 'Вы покидаете страницу?',
    text: 'Дождитесь окончания процесса.',
    confirmText: 'Покинуть',
    cancelText: 'Остаться',
  });

  const [isBlockedNavigation, setIsBlockedNavigation] = useState(false);

  const { showDialog, hideDialog } = useDialog();
  const navigate = useNavigate();

  const updateBlockerConfig = useCallback((config: Partial<IBlockerConfig>) => {
    setBlockerConfig((prev) => ({
      ...prev,
      ...config,
    }));
  }, []);

  const startBlockingNavigation = useCallback(() => {
    setIsBlockedNavigation(true);
  }, []);

  const stopBlockingNavigation = useCallback(() => {
    setIsBlockedNavigation(false);
  }, []);

  // Вызываем useBlocker только с булевым значением:
  const blocker = useBlocker(isBlockedNavigation);

  // Когда состояние блокировки равно 'blocked',
  // показываем диалог и по результату решаем: proceed или reset
  useEffect(() => {
    if (blocker.state === 'blocked') {
      showDialog({
        title: blockerConfig.title,
        text: blockerConfig.text,
        okBtnText: blockerConfig.confirmText,
        cancelBtnText: blockerConfig.cancelText,
        onClickOk: () => {
          hideDialog();
          stopBlockingNavigation();
          if (blockerConfig.onConfirm) {
            blockerConfig.onConfirm();
            blocker.proceed();
          } else {
            navigate(blocker.location.pathname + blocker.location.search);
            blocker.proceed();
          }
        },
        onClickCancel: () => {
          hideDialog();
          blockerConfig.onCancel?.();
          blocker.reset();
        },
      });
    }
  }, [blocker, blockerConfig, showDialog, hideDialog, navigate, stopBlockingNavigation]);

  const contextValue = useMemo(
    () => ({
      setBlockerConfig: updateBlockerConfig,
      startBlockingNavigation,
      stopBlockingNavigation,
      isBlockedNavigation,
      hideDialog,
    }),
    [updateBlockerConfig, startBlockingNavigation, stopBlockingNavigation, isBlockedNavigation],
  );

  return (
    <NavigationBlockerContext.Provider value={contextValue}>
      {children}
    </NavigationBlockerContext.Provider>
  );
};
