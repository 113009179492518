import React from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyContent } from '@components/EmptyContent/EmptyContent';

export default function EmptyPage404() {
  const { t } = useTranslation();

  return <EmptyContent title={t('common__page_not_found_error')} />;
}
